import React, { useState } from "react";

import { base, navigation } from "./Tabs.module.scss";

import { combine } from "../utils/classNames";

import Button from "./Button";

const Tabs = props => {
  const { items, className } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = index => {
    setActiveIndex(index);
  };

  return (
    <div className={combine(base, className)}>
      <nav className={navigation}>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <Button
                className={activeIndex === index ? "--is-active" : ""}
                key={index}
                isLink
                onClick={() => handleClick(index)}
              >
                {item.label}
              </Button>
            </li>
          ))}
        </ul>
      </nav>
      {items[activeIndex].content}
    </div>
  );
};

export default Tabs;
