import React, { useState } from "react";

import {
  base,
  accordionItem,
  accordionItemHeading,
  accordionItemContent,
} from "./Accordion.module.scss";

//

import { combine, getClassNameModifiers } from "../utils/classNames";

const AccordionItem = props => {
  const { heading, content, onClick } = props;

  const modifiers = getClassNameModifiers(props, ["isExpanded"]);

  return (
    <div className={accordionItem} onClick={onClick}>
      <div className={combine(accordionItemHeading, modifiers)}>
        {heading}
        <i className="material-icons">keyboard_arrow_down</i>
      </div>
      <div className={combine(accordionItemContent, modifiers)}>{content}</div>
    </div>
  );
};

const Accordion = props => {
  const { items, showAll } = props;

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleClick = index => {
    setExpandedIndex(index);
  };

  return (
    <div className={base}>
      {items &&
        items.length > 0 &&
        items.map((item, index) => (
          <AccordionItem
            heading={item.heading}
            content={item.content}
            isExpanded={showAll || expandedIndex === index}
            onClick={() => handleClick(index)}
          />
        ))}
    </div>
  );
};

export default Accordion;
