import React from "react";

import { header, searchContainer, searchInput, body } from "./FAQs.module.scss";

import Layout from "../layouts/Layout";
import Search from "../components/Search";
import Accordion from "../components/Accordion";
import Tabs from "../components/Tabs";
import ContactSection from "../partials/ContactSection";

import { useRegexSearch, useMediaQuery } from "../utils/hooks";

const FAQsPage = props => {
  const isMobile = useMediaQuery("(max-width: 575px)");

  const [setGeneralQuery, filteredGeneralFAQItems, generalSearchActive] = useRegexSearch(
    generalFAQItems,
    ["content"]
  );
  const [setBrandQuery, filteredBrandFAQItems, brandSearchActive] = useRegexSearch(brandFAQItems, [
    "content",
  ]);
  const [setPartnerQuery, filteredPartnerFAQItems, partnerSearchActive] = useRegexSearch(
    partnerFAQItems,
    ["content"]
  );

  const handleSearch = query => {
    setGeneralQuery(query);
    setBrandQuery(query);
    setPartnerQuery(query);
  };

  const searchActive = generalSearchActive || brandSearchActive || partnerSearchActive;

  return (
    <Layout
      pageTitle="FAQs | Brandcrush"
      ogTitle="Brandcrush: Your questions answered"
      ogDescription="Check out our FAQs for all the details on how Brandcrush works for businesses, brands and agencies."
    >
      <section className={header}>
        <h1>Frequently Asked Questions</h1>
        <div className={searchContainer}>
          <h2>How can we help?</h2>
          <p>Search by keyword (e.g 'activation' or 'sample')</p>
          <Search className={searchInput} onSearch={handleSearch} />
        </div>
      </section>
      <section className={body}>
        <Tabs
          items={[
            {
              label: "General",
              content: <Accordion key={0} items={filteredGeneralFAQItems} showAll={searchActive} />,
            },
            {
              label: "Media Buyer",
              content: <Accordion key={1} items={filteredBrandFAQItems} showAll={searchActive} />,
            },
            {
              label: "Media Owner",
              content: <Accordion key={2} items={filteredPartnerFAQItems} showAll={searchActive} />,
            },
          ]}
        />
      </section>
      <ContactSection
        heading="Want to know more?"
        body="Send us a message or give us a call and we'll answer any other questions you have."
        buttonLabel="Get in touch"
        backgroundColor="grey"
        buttonColor="cerise"
        headingColor="cerise"
        foregroundImg="/images/brand-contact.svg"
        foregroundImgX={4.438}
        foregroundImgY={isMobile ? -5.625 : -3.75}
        foregroundImgWidth={isMobile ? 18.875 : 37.938}
        height={24.375}
      />
    </Layout>
  );
};

const generalFAQItems = [
  {
    heading: "What is Brandcrush?",
    content: (
      <>
        <p>
          Brandcrush is an omnichannel owned media platform - unlocking the media potential of
          businesses globally with a simple but powerful solution that makes it easier to buy and
          sell shopper marketing and commercial partnerships.
        </p>
        <br />
        <p>
          We power retailers and broader businesses to transform their in-store media, out-of-store
          media and online brand partnerships from antiquated PDF packs and spreadsheet management
          to a scalable solution that the world's leading brands and agencies trust.
        </p>
        <br />
        <p>
          Our software enables our partners to unlock and grow their media revenue without having to
          grow their team with self-serve media portals, outbound sales tools, smart inventory and
          workflow management solutions.
        </p>
      </>
    ),
  },
  {
    heading: "Who is a Media Buyer?",
    content:
      "Brands and Agencies can seamlessly book shopper marketing, experiential activations and partnerships through a network of trusted businesses",
  },
  {
    heading: "What's an activation?",
    content:
      "An activation is a brand experience that makes your brand known to people, increasing awareness and engagement.",
  },
  {
    heading: "Who is a Media Owner?",
    content: (
      <>
        <p>
          Our end-to-end software solution powers businesses around the world — from grocery & D2C
          retailers, shopping centres, gym, coworking locations and universities — to monetize and
          maximize their media selling potential.
        </p>
        <br />
        <p>
          If you are a registered business and have a valuable customer reach, schedule a demo with
          our team to discover our solutions.
        </p>
      </>
    ),
  },
  {
    heading: "Is Brandcrush in all countries?",
    content:
      "Brandcrush is a global platform with current operations in Ausralia, United States, United Kingdom and New Zealand. For other countries please contact sales@brandcrush.com.",
  },
  {
    heading: "How does Brandcrush make money?",
    content:
      "Brandcrush is free for brands and Agencies. We charge transaction fees and subscription fees to our Media owners for use of the platform and offer a range of subscription types to suit our Media owners where ever they are on their media monetization journey.",
  },
  {
    heading: "Who executes the activation?",
    content:
      "Brands can choose to activate with their brand teams or they can request Media owners to activate for them.",
  },
  {
    heading: "How does Brandcrush work with Agencies?",
    content:
      "Brandcrush works closely with all PR, Activation and Media agencies, offering our platform as a workflow solution for their clients. We do not charge any platform fees to our brands and agencies. There is full transparency on our activation rates which are set by our partners. We do offer concierge support to our agency clients.",
  },
  {
    heading: "How does Brandcrush monitor Media owners & customer reach estimates?",
    content:
      "Brandcrush has rich insight into foot traffic and reach trends through the thousands of spaces listed on our platform. We track and compare customer reach by category to ensure all space listings fall within a validated threshold and review data additional data points.",
  },
];

const brandFAQItems = [
  {
    heading: "What is a booth or popup activation?",
    content:
      "Popup activations are a short term retail offering where a brand can sell product in a dedicated space. i.e a shoe brand selling at a gym or in a shopping mall.",
  },
  {
    heading: "What is D2C sampling?",
    content:
      "Product samples and brand offers are added into online shopping deliveries and subscription boxes and delivered directly to consumers.",
  },
  {
    heading: "What is a bathroom activation?",
    content:
      "Catch people at the right place and time by placing personal care, cosmetics or wellness products in bathrooms. This passive self-serve activation will invite customers to interact with your brand in an organic way when they will appreciate it most.",
  },
  {
    heading: "What is a countertop activation?",
    content:
      " Create a passive display for brands on countertops, catching the eye of customers while they're ordering coffee or paying the bill. This display could include a sample of your product, brand messaging, a discount voucher, or any other incentive.",
  },
  {
    heading: "What is a decal/poster activation?",
    content: "Brands can feature messages on decals and/or posters in a business space.",
  },
  {
    heading: "What is a digital screen activation?",
    content:
      "Reach customers on their path to purchase with rich video content delivered via in-store display screens.",
  },
  {
    heading: "What is a digital sponsorship?",
    content: "Brands are promoted via live streaming channels, email marketing and social media.",
  },
  {
    heading: "What is an event sponsorship?",
    content:
      "Engage with event attendees by sponsoring an event. Event sponsorship details are specific to each event.",
  },
  {
    heading: "What is an on the menu activation?",
    content: `Integrate your food or beverage in special "on the menu" items in popular cafes, restaurants or meal kit delivery boxes. Suggest a recipe to showcase your item, or partner's culinary teams chef come up with something truly memorable on your behalf.`,
  },
  {
    heading: "What is an on the trainer activation?",
    content:
      "Popular fitness trainers become ambassadors for your brand by wearing your sports apparel or work-out accessories.",
  },
  {
    heading: "What is a goody bag activation?",
    content:
      "Access engaged event attendees and have your product, message or voucher included in event goody bags. This is an easy, no-fuss activation which lets you leverage existing associations and join in with other brands on a group marketing effort.",
  },
  {
    heading: "What is a roaming activation?",
    content:
      "Brands bring in their own roaming brand ambassadors to take product samples and messages to the people.",
  },
  {
    heading: "What is a takeover activation?",
    content:
      "Brands bring in their own team and branding to take over a partner’s space (and socials) for a day — perfect for special occasions like PR events, parties and product launches.",
  },
  {
    heading: "What is a street space activation?",
    content: "Connect with consumers passing by in high foot-traffic street spaces.",
  },
  {
    heading: "How do samples get distributed?",
    content:
      "Once a booking request is approved, brands can download a CSV spreadsheet that includes a contact person, address, business name and volume of samples required per space. This CSV is then used by the brands' warehouse to distribute samples directly to the partner. Brandcrush can also provide a full delivery concierge service if needed. Select partners may send through a Purchase Order with delivery details.",
  },
  {
    heading: "What is the pricing structure for spaces?",
    content: "Partners set their own pricing for their activation opportunities.",
  },
  {
    heading: "What if we are a service based business?",
    content:
      "If you don’t have products to sample, why not distribute marketing materials or discount offers? Anything that adds value for consumers and builds awareness for your business can be achieved via Brandcrush.",
  },
  {
    heading: "Can we book floor space for us to run our own activation?",
    content:
      "Yes, simply use our search function to find spaces suitable for your activation. If you find a suitable space but need a specific space position (eg. floor), then you can message the partner directly with your request.",
  },
  {
    heading: "Can we brief promotional staff for an activation?",
    content:
      "Yes, brands can upload product information, scripts and marketing collateral when they set up their booking request. This is used to educate partners and promotional staff on site.",
  },
  {
    heading: "Can we sample and sell product?",
    content:
      "Yes, simply use our search functionality to find spaces that permit selling in their activation spaces.",
  },
  {
    heading: "What campaign reports are provided?",
    content:
      "When a partner runs an activation for a brand, they are required to provide a report at the end of every activation. This report includes insights, feedback from consumers as well as photos of the activation.",
  },
  {
    heading: "How much does it cost to book an activation?",
    content:
      "Our activation rates are set by our partners. There is full pricing transparency and partners can adjust their pricing. Rates are generally based on customer reach, so dependent on the reach the partner pricing will vary.",
  },
  {
    heading: "Can I pay on invoice?",
    content:
      "Yes, you can request to pay via invoice on your profile page or accounts@brandcrush.com if you would like to request approval.",
  },
  {
    heading: "When are credit card payments processed?",
    content:
      "Credit card payments are processed as soon as your activation request is accepted by a partner. You can find a summary of payments in your dashboard. Brandcrush holds the payment for a maximum of five days while your activation request is pending.",
  },
  {
    heading: "What is the Brandcrush service fee?",
    content:
      "Brandcrush does not charge any service fees to brands and agencies. We monetize on the partners.. This means the rates you see are the rates that are set by our partners who use our platform to promote and manage their experiential and partnerships media.",
  },
  {
    heading: "Why was a credit card payment taken before my activation request was approved?",
    content:
      "Brandcrush holds payment for a maximum of five days while your activation request is pending.",
  },
  {
    heading: "Are there any set up or subscription fees?",
    content:
      "There are no sign up fees for Brands and Agencies. Brandcrush has subscription fees for our partners.",
  },
  {
    heading: "Is there a minimum spend?",
    content:
      "There is no minimum spend. You can spend as much or as little as you like with Brandcrush. Most brands book a volume of spaces for a one or two week campaign",
  },
  {
    heading: "Can we pay with product only?",
    content:
      "Sometimes, yes. Use our search function to find product-in-kind opportunities on the platform.",
  },
  {
    heading: "When should I send merchandise for a campaign?",
    content:
      "Samples and materials need to arrive at the activation space as started on your activation booking or as advised by the partner, prior to your activation start date. Please include a copy of your Brandcrush booking confirmation with your product delivery for each space.",
  },
  {
    heading: "Why are my activation requests being declined?",
    content:
      "Partners are looking to partner with like-minded brands who complement their existing business and delight their customers. Please contact concierge@brandcrush.com to help find suitable spaces.",
  },
  {
    heading: "When will I know if my activation request is approved or declined?",
    content:
      "Partners are encouraged to respond within 24 hours. The space will be shown as booked until they respond. Any requests pending for five days or more will be automatically declined.",
  },
  {
    heading: "Can I submit a booking request for multiple spaces at once?",
    content:
      "Absolutely, you can build a national campaign in a few clicks. Simply add spaces using the campaign building, provide the booking details and submit your activation request.",
  },
  {
    heading: "Can I create a long term partnership with a space?",
    content:
      "Brands can book out activation spaces for extended periods providing them with effective or limited exclusivity with a partner.",
  },
  {
    heading: "Can I cancel a booking after being accepted by a Partner?",
    content:
      "Cancellation requests made 30 days prior to an activation date will receive a 100% refund. Any cancellation request made less than 30 days before the activation start date will be refundable at 50% of the activation rate. Any cancellation request made in less than 15 days of an activation start date will be non-refundable. Brandcrush will still charge transaction fees on all cancellation fees. Some partners may include supplementary terms with different cancellation policies, note these terms will supersede our terms so always review them when placing a booking.",
  },
  {
    heading:
      "What happens to our product, samples and materials if we cancel an activation and items have already been delivered?",
    content:
      "Brands can arrange for pick up and return of product and materials at their own expense or message partners to arrange the activation for another booking date.",
  },
  {
    heading: "Can I negotiate pricing with a Partner?",
    content:
      "Partners set prices based on what the marketplace is prepared to pay. You can negotiate for longer term bookings or use our in app messaging to contact the partner and negotiate. Brandcrush can also help concierge. For more information email concierge@brandcrush.com.",
  },
];

const partnerFAQItems = [
  {
    heading: "How do I become a Partner?",
    content: (
      <>
        <a href="/book-a-demo">
          <u>Schedule a demo</u>
        </a>{" "}
        with our sales team.
      </>
    ),
  },
  {
    heading: "What brands use Brandcrush?",
    content:
      "Brands works with the leading multinational brands and agencies globally.  Given the diversity of media opportunities on the platform - we work with brands across all verticals",
  },
  {
    heading: "Does it cost my brand & agency partners to sign up?",
    content:
      "No it is completely free for brands to sign up to your media site and the marketplace",
  },
  {
    heading: "Does Brandcrush take a comission?",
    content:
      "Yes, there is a transaction fee for each booking and this is dependent on the plan that the partner is on.",
  },
  {
    heading: "Can I approve/decline brands who want to activate in my space?",
    content: `Yes, all activation requests are submitted for approval so you can select the brands that are right for you. Or, you can choose to set up "auto-approve" when listing your media space.`,
  },
  {
    heading: "How do I get the Brand's samples and materials?",
    content:
      "You will be prompted through campaign workflows on the step by step process to activate for the brand/agency. Our Pro and Enterprise Clients can create their own custom workflow processes to suit their media.",
  },
  {
    heading: "How long do I have to respond to a booking request?",
    content:
      "Partners are encouraged to respond within 24 hours. A space can not be double booked while pending. Any requests pending for five days or more will automatically decline.",
  },
  {
    heading: "Why do I have to submit an activation report?",
    content: `For all activations that are run by the partner you are required to submit an activation report as "proof of activation".`,
  },
  {
    heading: "Can I include additional terms?",
    content:
      "Yes you can notify brands and agencies before you accept their booking request or when you send a proposal that you have supplementary terms.",
  },
  {
    heading: "How do I calculate customer reach?",
    content:
      "Customer reach is the number of consumers that a partner can connect a brand with during an activation. For experiential media: it is how many customers you estimate will receive a sample or marketing message during an activation in your space or at your event. For digital and print media: it is the audience numbers or foot traffic that will see the brand’s message. It is important that businesses do not over-represent their customer reach as it will create logistical issues and a disappointing experience for the brand.",
  },
  {
    heading: "What happens if there are samples leftover at the end of a scheduled activation?",
    content:
      "Partners are required to distribute the number of samples indicated in the booking confirmation. If an extension is required then they must notify Brandcrush immediately via concierge@brandcrush.com or support@brandcrush.com.",
  },
  {
    heading: "How do Brands measure activation success?",
    content:
      "Brands can activate strategically to support their offline and online retail distribution. We recommend that they include a CTA with their activations to drive measurement. The more engaged your customers are, the more likely they are to purchase the product/service and the more chance you have of getting another booking.",
  },
  {
    heading: "Can I distribute samples as soon as they arrive?",
    content:
      "You need to mark all samples as received under the activation booking. Activations should only take place on the specified activation date. This allows brands to measure the ROI of the activation.",
  },
  {
    heading: "How do I handle price discounts/variations?",
    content:
      "You can prepare discounted offers or packages using the outbound proposal tool. Volume discounts can also be applied to your media spaces.",
  },
  {
    heading: "Are brands allowed to negotiate?",
    content:
      "Brands can negotiate for longer-term bookings or if they feel you are overpriced. Brandcrush provides suggested pricing in the space set up process however you are also welcome to contact concierge@brandcrush.com for further information.",
  },
  {
    heading: "How are payouts managed?",
    content: "Payout terms will be stated on your susbcription agreement.",
  },
  {
    heading: "What is the cancellation policy?",
    content: "View our Terms of Service for full details on cancellation policies.",
  },
  {
    heading: "Can I create a long term partnership with a Brand?",
    content:
      "Yes, to increase your chances of building ongoing partnership please provide prompt and extensive activation reports.",
  },
];

export default FAQsPage;
